.about {
    display: flex;
    flex-direction: column;
    align-items: center;

    background-image: url("../images/comp.jpg");
    background-position: center;
    background-size: cover;
    height: 500px;
    font-family: 'Mukta';

    color: white;
    /* background-color: rgba(240, 140, 140, 0.5); */
}

.about-color {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: RGBA(33, 101, 131, 0.8)
}

.about-content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 800px;
    text-align: center;
}

.about-me-title {
    font-size: 45px;
}

@media only screen and (max-width: 800px) {
    .about-content-container {
        width: 575px;
    }
}