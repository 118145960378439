.nav-bar {
  display: flex;
  justify-content: flex-end;

  overflow: hidden;
  position: fixed; /* Set the navbar to fixed position */
  top: 0; /* Position the navbar at the top of the page */
  width: 100%;

  font-family: 'Mukta';
  transition: background-color 0.2s;
}

.nav-bar-v {
  display: flex;
  justify-content: flex-end;

  overflow: hidden;
  position: fixed; /* Set the navbar to fixed position */
  top: 0; /* Position the navbar at the top of the page */
  width: 100%;

  background-color: white;
  font-family: 'Mukta';

  border-bottom: 1px solid lightgray;
  transition: background-color 0.2s;
  /* z-index: 10; */
}

.nav-bar-content {
    padding: 20px;
    padding-bottom: 0px;
}

.nav-bar-content-v {
  padding: 20px;
  padding-bottom: 0px;
}

.nav-bar-button {
  /* margin-right: 15px;
  margin-left: 15px; */
  color: white;
  padding: 0px 15px 15px;
  border-bottom: 3px solid rgba(255, 255, 255, 0);
  transition: border-bottom 0.1s, color 0.2s;
}

.nav-bar-button-v {
  color: RGB(210, 77, 87);
  /* padding: 0px 15px 15px; */
  padding: 0px 15px 15px;
  border-bottom: 3px solid rgba(255, 255, 255, 0);
  transition: border-bottom 0.1s, color 0.2s;
}

.nav-bar-button:hover {
  border-bottom: 3px solid white;
}

.nav-bar-button-v:hover {
  border-bottom: 3px solid RGB(210, 77, 87);
}