/* :target {
    display: block;    
    position: relative;
    top: -100px;
    margin-top: 100px;
    margin-bottom: -100px;
} */

/* :target::before {
    content: '';
    display: block;
    height:      180px;
    top: -100px;
} */

/* a[id]::before {
    content: '';
    display: block;
    height: 100px;
    margin: -300px;
} */

#WEB {
    display: block;
    position: relative;
    top: -100px;
    visibility: hidden;
}

#iOS {
    display: block;
    position: relative;
    top: -100px;
    visibility: hidden;
}

#about {
    display: block;
    position: relative;
    top: -100px;
    visibility: hidden;
}

#contact {
    display: block;
    position: relative;
    top: -100px;
    visibility: hidden;
}

.portfolio-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 200px;
    background-color: rgb(248, 248, 248);
    font-family: 'Mukta';
}

.portfolio-header-content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 700px;
}

.portfolio-header-title {
    margin-bottom: 15px;
}

.portfolio-header-info {
    font-family: 'Roboto';
}

/* .portfolio-header-link-div {
    display: block;
    position: relative;
    top: -100px;
    visibility: hidden;
} */

@media only screen and (max-width: 800px) {
    .portfolio-header-info {
        font-size: 0px;
    }

    .portfolio-header {
        height: 100px;
        padding-top: 20px;
    }
}

@media only screen and (max-width: 700px) {
    .portfolio-header-content-container {
        width: 600px;
    }
}