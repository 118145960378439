html {
    /* font-family: 'Noto Sans HK', 'PT Sans', sans-serif; */
    font-family: 'Roboto', 'Mukta', sans-serif;
    min-height: 100%;
    scroll-behavior: smooth;
}

h1 {
    font-size: 32px;
}

h2 {
    font-size: 24px;
}

h3 {
    font-size: 19px;
}

body {
    width: 100%;
    height: 100%;
}

button {
    font-size: 16px;
}

.simple-column-center {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.simple-row {
    display: flex;
}

.simple-row-center {
    display: flex;
    justify-content: center;
}

.simple-row-space-around {
    display: flex;
    justify-content: space-around;
}