.contact {
    display: flex;
    flex-direction: column;
    align-items: center;

    color: white;
    background-color: RGB(210, 77, 87);

    padding: 60px;
    padding-bottom: 100px;
    text-align: center;
}

.contact-content-container {
    width: 700px;
}

.contact-title {
    font-size: 40px;
    font-family: 'Mukta';
}

.contact-button {
    color: white;
    border: 2px solid white;
    padding: 15px 40px;
    margin-top: 20px;
    margin-right: 20px;
    border-radius: 8px;
    transition: background-color 0.1s, color 0.1s;
}

.contact-button:hover {
    color: RGB(210, 77, 87);
    background-color: white;
}

@media only screen and (max-width: 700px) { 

    .contact-title {
        font-size: 35px;
    }
    .contact-content-container {
        width: 600px;
    }
}